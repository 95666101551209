import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Notification } from '@swagger/models';

import { NotificationActions } from './notification.actions';

interface State {
  error: unknown;
  loading: boolean;
  notificationDetailed: Notification | undefined;
  notifications: Notification[];
}

const initialState: State = {
  error: undefined,
  loading: false,
  notificationDetailed: undefined,
  notifications: [],
};

const reducer = createReducer(
  initialState,
  on(
    NotificationActions.loadNotifications,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    NotificationActions.loadNotificationsSuccess,
    (state, { notifications }): State => ({
      ...state,
      error: undefined,
      loading: false,
      notifications,
    }),
  ),
  on(
    NotificationActions.loadNotificationsFailure,
    (state, { error }): State => ({
      ...state,
      error,
      loading: false,
    }),
  ),
  on(
    NotificationActions.markNotificationsAsWatchedSuccess,
    (state, { payload }): State => ({
      ...state,
      notifications: state.notifications.map((notification) =>
        payload.ids.includes(<number>notification.id)
          ? { ...notification, watched: true }
          : notification,
      ),
    }),
  ),
  on(
    NotificationActions.loadNotificationDetailedSuccess,
    (state, { notification }): State => ({
      ...state,
      notificationDetailed: notification,
    }),
  ),
);

export const notificationFeature = createFeature({
  extraSelectors: ({ selectNotifications }) => ({
    selectTotalNewNotifications: createSelector(
      selectNotifications,
      (notifications) =>
        notifications.filter((notification) => !notification.watched).length,
    ),
  }),
  name: 'notification',
  reducer,
});
