/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Notification } from '../models/notification';
import { NotificationCreate } from '../models/notification-create';
import { NotificationWatched } from '../models/notification-watched';
@Injectable({
  providedIn: 'root',
})
class NotificationService extends __BaseService {
  static readonly notificationListListPath = '/notification/list';
  static readonly notificationListCreatePath = '/notification/list';
  static readonly notificationWatchedCreatePath = '/notification/watched';
  static readonly notificationReadPath = '/notification/{id}';
  static readonly notificationUpdatePath = '/notification/{id}';
  static readonly notificationPartialUpdatePath = '/notification/{id}';
  static readonly notificationDeletePath = '/notification/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `NotificationService.NotificationListListParams` containing the following parameters:
   *
   * - `source`: тип уведомления
   *
   * - `project`: project
   *
   * - `profile`: profile
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  notificationListListResponse(params: NotificationService.NotificationListListParams): __Observable<__StrictHttpResponse<Array<Notification>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.source != null) __params = __params.set('source', params.source.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.profile != null) __params = __params.set('profile', params.profile.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notification/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Notification>>;
      })
    );
  }
  /**
   * @param params The `NotificationService.NotificationListListParams` containing the following parameters:
   *
   * - `source`: тип уведомления
   *
   * - `project`: project
   *
   * - `profile`: profile
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  notificationListList(params: NotificationService.NotificationListListParams): __Observable<Array<Notification>> {
    return this.notificationListListResponse(params).pipe(
      __map(_r => _r.body as Array<Notification>)
    );
  }

  /**
   * @param data undefined
   */
  notificationListCreateResponse(data: NotificationCreate): __Observable<__StrictHttpResponse<Notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notification/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notification>;
      })
    );
  }
  /**
   * @param data undefined
   */
  notificationListCreate(data: NotificationCreate): __Observable<Notification> {
    return this.notificationListCreateResponse(data).pipe(
      __map(_r => _r.body as Notification)
    );
  }

  /**
   * @param data undefined
   */
  notificationWatchedCreateResponse(data: NotificationWatched): __Observable<__StrictHttpResponse<NotificationWatched>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notification/watched`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationWatched>;
      })
    );
  }
  /**
   * @param data undefined
   */
  notificationWatchedCreate(data: NotificationWatched): __Observable<NotificationWatched> {
    return this.notificationWatchedCreateResponse(data).pipe(
      __map(_r => _r.body as NotificationWatched)
    );
  }

  /**
   * @param id A unique integer value identifying this notification.
   */
  notificationReadResponse(id: number): __Observable<__StrictHttpResponse<Notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notification/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notification>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this notification.
   */
  notificationRead(id: number): __Observable<Notification> {
    return this.notificationReadResponse(id).pipe(
      __map(_r => _r.body as Notification)
    );
  }

  /**
   * @param params The `NotificationService.NotificationUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this notification.
   *
   * - `data`:
   */
  notificationUpdateResponse(params: NotificationService.NotificationUpdateParams): __Observable<__StrictHttpResponse<Notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/notification/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notification>;
      })
    );
  }
  /**
   * @param params The `NotificationService.NotificationUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this notification.
   *
   * - `data`:
   */
  notificationUpdate(params: NotificationService.NotificationUpdateParams): __Observable<Notification> {
    return this.notificationUpdateResponse(params).pipe(
      __map(_r => _r.body as Notification)
    );
  }

  /**
   * @param params The `NotificationService.NotificationPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this notification.
   *
   * - `data`:
   */
  notificationPartialUpdateResponse(params: NotificationService.NotificationPartialUpdateParams): __Observable<__StrictHttpResponse<Notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/notification/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notification>;
      })
    );
  }
  /**
   * @param params The `NotificationService.NotificationPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this notification.
   *
   * - `data`:
   */
  notificationPartialUpdate(params: NotificationService.NotificationPartialUpdateParams): __Observable<Notification> {
    return this.notificationPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Notification)
    );
  }

  /**
   * @param id A unique integer value identifying this notification.
   */
  notificationDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/notification/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this notification.
   */
  notificationDelete(id: number): __Observable<null> {
    return this.notificationDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NotificationService {

  /**
   * Parameters for notificationListList
   */
  export interface NotificationListListParams {

    /**
     * тип уведомления
     */
    source?: 'global' | 'stage' | 'bp_task';

    /**
     * project
     */
    project?: string;

    /**
     * profile
     */
    profile?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for notificationUpdate
   */
  export interface NotificationUpdateParams {

    /**
     * A unique integer value identifying this notification.
     */
    id: number;
    data: NotificationCreate;
  }

  /**
   * Parameters for notificationPartialUpdate
   */
  export interface NotificationPartialUpdateParams {

    /**
     * A unique integer value identifying this notification.
     */
    id: number;
    data: NotificationCreate;
  }
}

export { NotificationService }
