import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Notification, NotificationWatched } from '@swagger/models';
import { NotificationService } from '@swagger/services/notification.service';

export const NotificationActions = createActionGroup({
  events: {
    'Load Notification Detailed': emptyProps(),
    'Load Notification Detailed Failure': props<{ error: unknown }>(),
    'Load Notification Detailed Success': props<{
      notification: Notification;
    }>(),

    'Load Notifications': props<{
      payload: NotificationService.NotificationListListParams;
    }>(),
    'Load Notifications Failure': props<{ error: unknown }>(),
    'Load Notifications Success': props<{ notifications: Notification[] }>(),

    'Mark Notifications As Watched': props<{ payload: NotificationWatched }>(),
    'Mark Notifications As Watched Failure': props<{
      error: unknown;
    }>(),
    'Mark Notifications As Watched Success': props<{
      payload: NotificationWatched;
    }>(),
  },
  source: 'Notification',
});
